import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2424468505/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Android SDK reference `}<a style={{
        "color": "#111"
      }} className="improve-docs" href="/select/sdks/android/reference-v1">{`v1`}</a>{` `}<a style={{
        "marginRight": "auto",
        "borderBottom": "2px solid #0048ff"
      }} className="improve-docs" href="/select/sdks/android/reference-v2">{`v2`}</a></h1>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#class-fidel"
        }}>{`Fidel class`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#properties"
        }}>{`Properties`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#methods"
        }}>{`Methods`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#callbacks"
        }}>{`Callbacks`}</a></li>
    </ol>
    <h2>{`class Fidel`}</h2>
    <p>{`This class is designed as a facade, used to configure the card enrollment process, via many of its `}<a parentName="p" {...{
        "href": "#properties"
      }}>{`static properties`}</a>{`, and start the `}<a parentName="p" {...{
        "href": "#methods"
      }}>{`card enrollment flow`}</a>{`. It's also the class that provides `}<a parentName="p" {...{
        "href": "#callbacks"
      }}>{`callbacks`}</a>{` that might be useful for your application.`}</p>
    <h2>{`Properties`}</h2>
    <h3>{`Mandatory properties`}</h3>
    <p>{`These are properties that must be set correctly. In the case where one of these properties are not set or they are set incorrectly, the SDK will return an error in the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback (of type: `}<inlineCode parentName="p">{`FidelErrorType.SdkConfigurationError`}</inlineCode>{`).`}</p>
    <h4>{`sdkKey: String`}</h4>
    <p>{`This key is used to authenticate your Fidel API account. Get it from your Fidel API dashboard -> Account Settings -> SDK Keys section.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Important note: For security reasons, please DO NOT store the SDK Key in your codebase. Follow our `}<a parentName="p" {...{
          "href": "/select/sdks/security-guidelines"
        }}>{`SDK security guide`}</a>{` for detailed recommendations.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Note: If you use a `}<strong parentName="p">{`test SDK Key`}</strong>{`, your users can only enroll `}<a parentName="p" {...{
          "href": "/select/cards/#test-card-numbers"
        }}>{`test card numbers`}</a>{`.`}</p>
    </blockquote>
    <h4>{`programId: String`}</h4>
    <p>{`The program ID indicates the Fidel API program in which the cards will be enrolled. Get the program ID by navigating to the Fidel API dashboard -> Programs section -> Click on the ID of the program you want to use. Clicking on it will copy the ID in your pasteboard.`}</p>
    <h4>{`companyName: String`}</h4>
    <p>{`By setting this property we customize the consent text, that the cardholder needs to read and agree with, before enrolling a card.`}</p>
    <p>{`The maximum number of characters allowed for this property is `}<inlineCode parentName="p">{`60`}</inlineCode>{`.`}</p>
    <h4>{`termsAndConditionsUrl: String`}</h4>
    <p>{`You need to set your terms and conditions URL if you would like to:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`support all the countries that Fidel API supports`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`set a specific `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` set of countries AND include US or Canada in it.`}</p>
      </li>
    </ol>
    <p>{`By setting this property we add a link to your Terms & Conditions in the consent text. The cardholder needs to read and agree with your terms, before enrolling a card.`}</p>
    <h3>{`Optional properties`}</h3>
    <h4>{`supportedCardSchemes`}</h4>
    <p>{`Type: `}<inlineCode parentName="p">{`Set<CardScheme>`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`setOf(CardScheme.VISA, CardScheme.MASTERCARD, CardScheme.AMERICAN_EXPRESS)`}</inlineCode></p>
    <p>{`Sets a list of supported card schemes. If a card scheme is supported, cardholders will be able to enroll their card. If a card scheme is not in the list, then the cardholders will see an error message while typing or pasting the unsupported card number.`}</p>
    <p>{`If you set a `}<inlineCode parentName="p">{`null`}</inlineCode>{` value, you will not be able to start the Fidel SDK enrollment flow. In this case, immediately after attempting to start the flow, you will receive an error in the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback (of type: `}<inlineCode parentName="p">{`FidelErrorType.SdkConfigurationError`}</inlineCode>{`).`}</p>
    <h4>{`enum class CardScheme`}</h4>
    <p>{`Cases:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`VISA`}</inlineCode>{`, `}<inlineCode parentName="li">{`MASTERCARD`}</inlineCode>{`, `}<inlineCode parentName="li">{`AMERICAN_EXPRESS`}</inlineCode></li>
    </ul>
    <h4>{`allowedCountries`}</h4>
    <p>{`Type: `}<inlineCode parentName="p">{`Set<Country>`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`setOf(Country.CANADA, Country.IRELAND, Country.JAPAN, Country.NORWAY, Country.SWEDEN, Country.UNITED_ARAB_EMIRATES, Country.UNITED_KINGDOM, Country.UNITED_STATES)`}</inlineCode></p>
    <p>{`Sets the list of countries that cardholders can pick to be the card issuing country. When two or more countries are set, cardholders will be able to select the card issuing country with our country selection UI.`}</p>
    <p>{`If you set a value with only one country (`}<inlineCode parentName="p">{`size`}</inlineCode>{` of this set == 1), the country selection UI will not be displayed in the card enrollment screen. The country that you set will be considered the card issuing country for all cards enrolled in your Fidel API program using the SDK.`}</p>
    <p>{`If you set an empty value, you will not be able to start the enrollment flow. Instead you will receive an error in the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback (`}<inlineCode parentName="p">{`FidelErrorType.SdkConfigurationError`}</inlineCode>{`), immediately after the attempt to start.`}</p>
    <h5>{`enum class Country`}</h5>
    <p>{`Cases:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CANADA, IRELAND, JAPAN, NORWAY, SWEDEN, UNITED_ARAB_EMIRATES, UNITED_KINGDOM, UNITED_STATES`}</inlineCode></li>
    </ul>
    <h4>{`deleteInstructions: String`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`"going to your account settings"`}</inlineCode></p>
    <p>{`This text informs the cardholder how to opt out of transaction monitoring in your program. It is appended at the end of the consent text. The maximum number of characters allowed for this property is `}<inlineCode parentName="p">{`60`}</inlineCode>{`.`}</p>
    <h4>{`defaultSelectedCountry: Country`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`UNITED_KINGDOM`}</inlineCode></p>
    <p>{`Sets the `}<inlineCode parentName="p">{`Country`}</inlineCode>{` that will be selected by default when the user opens the card enrollment screen. If the `}<inlineCode parentName="p">{`defaultSelectedCountry`}</inlineCode>{` is not part of the `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` list, then the first country in the `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` list will be selected.`}</p>
    <h4>{`privacyPolicyUrl: String?`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`If you provide a value for this parameter, the card enrollment consent text will include a phrase that will provide the user with more privacy related information at the URL that you provide.`}</p>
    <p>{`When the value of this parameter remains `}<inlineCode parentName="p">{`null`}</inlineCode>{` no such phrase will be displayed in the card enrolling consent text.`}</p>
    <p>{`If you provide an invalid URL string, you will not be able to start the card enrollment flow. Instead you will receive an error in the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback (`}<inlineCode parentName="p">{`FidelErrorType.SdkConfigurationError`}</inlineCode>{`), immediately after attempting to start the card enrollment flow.`}</p>
    <h4>{`metaData: org.json.JSONObject?`}</h4>
    <p>{`This is a JSONObject that you can use to associate custom data with an enrolled card.`}</p>
    <p>{`We advise setting an `}<inlineCode parentName="p">{`"id"`}</inlineCode>{` key -> value pair in this JSONObject. Later, it might be useful for you to use our `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/list-cards-from-metadata-id"
      }}>{`List Cards from Metadata ID`}</a>{` API Endpoint to query for cards using this ID.`}</p>
    <p>{`Example of meta data that you can set:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`val jsonMetaData = JSONObject()
jsonMetaData.put("id", "this-is-the-metadata-id")
jsonMetaData.put("myUserId", "123")
jsonMetaData.put("customKey", "customValue")
Fidel.metaData = jsonMetaData
`}</code></pre>
    <p>{`You would receive a JSONObject that is equal to this one, after successfully enrolling a card, in the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback, in the `}<inlineCode parentName="p">{`EnrollmentResult`}</inlineCode>{` object.`}</p>
    <h4>{`bannerImage: Bitmap?`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`Will display the banner image that you set in this parameter at the top of the card details screen.`}</p>
    <p>{`The banner image will take the device's width, but it has a fixed height of 100 dp.
The image view has the `}<inlineCode parentName="p">{`android:scaleType="centerCrop"`}</inlineCode>{` attribute, which means that the banner image that you set will fill its entire predefined area.`}</p>
    <p>{`For the banner image that you can set, we suggest to use the aspect ratio of the smallest devices that you support. On wider devices, the banner image will be cropped from top and bottom sides. This is because of the `}<inlineCode parentName="p">{`android:scaleType="centerCrop"`}</inlineCode>{` attribute that we set for the image view.`}</p>
    <p>{`If a device that opens the SDK has 320dp in width, the aspect ratio of the image view would be `}<em parentName="p">{`320 : 100`}</em>{`.
If a device that opens the SDK has 475dp in width, the aspect ratio of your banner image would be `}<em parentName="p">{`475 : 100`}</em>{`.`}</p>
    <p>{`You need to provide the image for all screen densities.`}</p>
    <p>{`Depending on what you want to display in the banner image, you might need to experiment a bit to make sure that nothing important from the image is hidden. The most important information should be displayed in the centre of the banner image.`}</p>
    <h4>{`programName: String`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`"our"`}</inlineCode></p>
    <p>{`This value is used in the consent text when enrolling a card issued in a United States or Canada.`}</p>
    <h4>{`programType: ProgramType`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`ProgramType.TRANSACTION_SELECT`}</inlineCode></p>
    <p>{`It specifies the type of program you want to enroll cards into. The `}<inlineCode parentName="p">{`ProgramType`}</inlineCode>{` influences the flow that the SDK will show to cardholders when enrolling cards. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: For your Loyalty application, you need to use a Transaction Select program, so you can either explicitly set the value to `}<inlineCode parentName="p">{`TRANSACTION_SELECT`}</inlineCode>{` or not set this property (because its default value is `}<inlineCode parentName="p">{`TRANSACTION_SELECT`}</inlineCode>{`).`}</p>
    </blockquote>
    <h4>{`thirdPartyVerificationChoice: Boolean`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>
    <h2>{`Methods`}</h2>
    <h3>{`start(context: Context)`}</h3>
    <p>{`Starts a card enrollment flow. If you set the `}<inlineCode parentName="p">{`programType`}</inlineCode>{` to:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`TRANSACTION_STREAM`}</inlineCode>{`, a card enrollment flow will be started, for a Transaction Stream program (usually used by Stream applications).`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`TRANSACTION_SELECT`}</inlineCode>{`, a regular card enrollment flow will be started, for your Transaction Select program (usually used by Loyalty applications).`}</li>
    </ol>
    <h4>{`Parameters`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`context: Context`}</inlineCode>{`: the `}<inlineCode parentName="li">{`Context`}</inlineCode>{` that will start the card enrollment flow. This is a `}<em parentName="li">{`mandatory`}</em>{` parameter.`}</li>
    </ul>
    <h3>{`verifyCard(context: Context, cardVerificationConfiguration: CardVerificationConfiguration)`}</h3>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>
    <h3>{`onMainActivityCreate(context: Context)`}</h3>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>
    <h2>{`Callbacks`}</h2>
    <p>{`The SDK provides the following callbacks:`}</p>
    <h3>{`onResult: OnResultObserver`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`OnResultObserver`}</inlineCode>{` interface has a single function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`fun onResultAvailable(result: FidelResult)
`}</code></pre>
    <p>{`It will be called when a `}<inlineCode parentName="p">{`FidelResult`}</inlineCode>{` is available, during the card enrollment process. It can be called multiple times with different types of results.`}</p>
    <h4>{`sealed class FidelResult`}</h4>
    <p>{`Types of results:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Enrollment(val enrollmentResult: EnrollmentResult)`}</inlineCode>{`: Received after successfully enrolling the card in your Fidel API program. Please find details about `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` below.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Verification(val verificationResult: VerificationResult)`}</inlineCode>{`.`}<em parentName="li">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Error(val error: FidelError)`}</inlineCode>{`. An error occurred either during the card enrollment process. Please find details about `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` below.`}</li>
    </ul>
    <h4>{`data class EnrollmentResult`}</h4>
    <p>{`A result that can be received via the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback, after a card is successfully enrolled in your Fidel API program.`}</p>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`cardId: String`}</inlineCode>{`: The identifier of the card enrolled with your Fidel API program.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accountId: String`}</inlineCode>{`: The Fidel API account identifier.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`programId: String`}</inlineCode>{`: The identifier of the program that the card was enrolled into.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`enrollmentDate: Long`}</inlineCode>{`: The date and time when the card was enrolled, expressed as a `}<inlineCode parentName="li">{`Long`}</inlineCode>{` value, that you can use with your preferred date & time APIs.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scheme: CardScheme`}</inlineCode>{`: The enrolled card's scheme.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`isLive: Boolean`}</inlineCode>{`: This property will be `}<inlineCode parentName="li">{`true`}</inlineCode>{` when your Fidel API account is live and the card was enrolled in your `}<inlineCode parentName="li">{`live`}</inlineCode>{` Fidel API program. If the program that you enrolled the card into is not a `}<inlineCode parentName="li">{`live`}</inlineCode>{` one, then this property will be `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardFirstNumbers: String?`}</inlineCode>{`: If available, this property will be populated with the first 6 numbers of the enrolled card. To turn on or off receiving these numbers, please check your Fidel API account's settings.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardLastNumbers: String?`}</inlineCode>{`: If available, this property will be populated with the last 4 numbers of the enrolled card. To turn on or off receiving these numbers, please check your Fidel API account's settings.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardExpirationYear: Int`}</inlineCode>{`: The expiration year of the enrolled card. The values are four digit year values (ex: 2031), `}<strong parentName="li">{`not`}</strong>{` shortened, two digit values (ex: 31).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardExpirationMonth: Int`}</inlineCode>{`: The expiration month of the enrolled card. The values start with `}<inlineCode parentName="li">{`1`}</inlineCode>{` (January) and end with `}<inlineCode parentName="li">{`12`}</inlineCode>{` (December).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardIssuingCountry: Country`}</inlineCode>{`: The country where the enrolled card was issued.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`metaData: JSONObject?`}</inlineCode>{`: Custom data assigned to the enrolled card via the `}<inlineCode parentName="li">{`metaData`}</inlineCode>{` SDK property.`}</li>
    </ul>
    <h4>{`data class VerificationResult`}</h4>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>
    <h4>{`data class FidelError`}</h4>
    <p>{`A FidelError can occur during the card enrollment process. You can handle them via the `}<inlineCode parentName="p">{`onResult`}</inlineCode>{` callback.`}</p>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`message: String`}</inlineCode>{`: An error message explaining more details about the error. It is not localized.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`date: Long`}</inlineCode>{`: The date and time when the error occurred, expressed as a `}<inlineCode parentName="li">{`Long`}</inlineCode>{` value. You can use it with your preferred date & time APIs.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type: FidelErrorType`}</inlineCode>{`: The type of the error. Please check more details about `}<inlineCode parentName="li">{`FidelErrorType`}</inlineCode>{` below.`}</li>
    </ul>
    <h4>{`sealed class FidelErrorType`}</h4>
    <p>{`Types of errors:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`SdkConfigurationError`}</inlineCode>{`: The SDK `}<a parentName="li" {...{
          "href": "#properties"
        }}>{`properties`}</a>{` configuration is incorrect or incomplete. You can receive this error as soon as you attempt to start a flow using the SDK `}<a parentName="li" {...{
          "href": "#methods"
        }}>{`methods`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UserCanceled`}</inlineCode>{`: The user canceled the card enrollment flow at any stage.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DeviceNotSecure`}</inlineCode>{`: The device that the SDK is running on is not secure (for example, when it is rooted).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`EnrollmentError(val type: EnrollmentErrorType)`}</inlineCode>{`. An error that is received during card enrollment or during consent creation. Please check more details about `}<inlineCode parentName="li">{`EnrollmentErrorType`}</inlineCode>{` below.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`VerificationError(val type: VerificationErrorType)`}</inlineCode>{`. `}<em parentName="li">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></li>
    </ul>
    <h4>{`enum class EnrollmentErrorType`}</h4>
    <p>{`An enum containing some of the cases of card enrollment errors.`}</p>
    <p>{`Cases:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_ALREADY_EXISTS`}</inlineCode>{`: The card was already enrolled in your Fidel API program. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`map.already.exists`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`INVALID_PROGRAM_ID`}</inlineCode>{`: The program ID used to configure the SDK is not valid. If you receive this error, please make sure that you set a valid program ID via the `}<inlineCode parentName="li">{`programId`}</inlineCode>{` property. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`map.already.exists`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`INVALID_SDK_KEY`}</inlineCode>{`: The SDK Key used to configure the Fidel SDK is not valid. If you receive this error, please make sure that you set a valid SDK Key via the `}<inlineCode parentName="li">{`sdkKey`}</inlineCode>{` property. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`credential`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`INEXISTENT_PROGRAM`}</inlineCode>{`: The program ID used to configure the Fidel SDK is of a program that does not exist. If you receive this error, please make sure that you set the correct program ID via the `}<inlineCode parentName="li">{`programId`}</inlineCode>{` property. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`item-exists`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UNAUTHORIZED`}</inlineCode>{`: The card enrollment process is not authorized. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`Unauthorized`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UNEXPECTED`}</inlineCode>{`: An unexpected error during the card enrollment step.`}</li>
    </ul>
    <p>{`The following list of cases are `}<em parentName="p">{`not used`}</em>{` by Loyalty/Select Transactions use cases, at the moment, so you can ignore them:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_ISSUER_PROCESSING_CHARGE_ERROR`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_DUPLICATE_TRANSACTION_ERROR`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_INSUFFICIENT_FUNDS_ERROR`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_PROCESSING_CHARGE_ERROR`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_INCORRECT_CARD_DETAILS_ERROR`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_CARD_LIMIT_EXCEEDED`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`CARD_CONSENT_ERROR_GENERIC`}</inlineCode></li>
    </ul>
    <h3>{`onCardVerificationStarted: OnCardVerificationStartedObserver`}</h3>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>
    <h3>{`onCardVerificationChoiceSelected: OnCardVerificationChoiceSelectedObserver `}<em parentName="h3">{`(!Experimental)`}</em></h3>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      